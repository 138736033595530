import WeekPlanningComponent from '@/components/week-planning-component/WeekPlanningComponent.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component<WeekPlanning>({
  components: {
    WeekPlanningComponent,
  },
})
export default class WeekPlanning extends Vue {
  public $pageTitle = 'Week planning';

  protected mounted() {
  }
}
